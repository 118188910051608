@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

html {
    @apply font-clash;
  }


@font-face {
    font-family: 'Clash Grotesk';
    src: url('../src/assets/fonts/ClashGrotesk-Light.woff2') format('woff2'),
         url('../src/assets/fonts/ClashGrotesk-Light.woff') format('woff');
    font-weight: 300; /* Light */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Clash Grotesk';
    src: url('../src/assets/fonts/ClashGrotesk-Regular.woff2') format('woff2'),
         url('../src/assets/fonts/ClashGrotesk-Regular.woff') format('woff');
    font-weight: 400; /* Normal */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Clash Grotesk';
    src: url('../src/assets/fonts/ClashGrotesk-Medium.woff2') format('woff2'),
         url('../src/assets/fonts/ClashGrotesk-Medium.woff') format('woff');
    font-weight: 500; /* Medium */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Clash Grotesk';
    src: url('../src/assets/fonts/ClashGrotesk-Semibold.woff2') format('woff2'),
         url('../src/assets/fonts/ClashGrotesk-Semibold.woff') format('woff');
    font-weight: 600; /* Semibold */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Clash Grotesk';
    src: url('../src/assets/fonts/ClashGrotesk-Bold.woff2') format('woff2'),
         url('../src/assets/fonts/ClashGrotesk-Bold.woff') format('woff');
    font-weight: 700; /* Bold */
    font-style: normal;
    font-display: swap;
}

body{
    margin: 0;
    height: 100vh;
}